import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import App from "./App";
import BlackDashboard from "./plugins/blackDashboard";
import i18n from "./i18n";
import "./registerServiceWorker";
import store from './store';  
import axios from 'axios';
import cookie from "js-cookie";
import Swal from 'sweetalert2';
import '@/assets/scss/style.scss';
import router from './router';  

const axiosinstance = axios.create({
  baseURL: 'https://payrollium.b2camp.id/',
  timeout: 180000,
  headers: {
    'Content-Type': 'application/json',
  }
});

const token = cookie.get('token');
if (token !== undefined) {    
  const auth = JSON.parse(token); 
  store.dispatch('setLogin', auth.token); 
  axiosinstance.interceptors.request.use(
    (config) => {
      config.headers['Authorization'] = `Bearer ${auth.token}`;
      return config;
    },
    (error) => Promise.reject(error)
  );
}

Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);

Vue.prototype.$axios = axiosinstance;
Vue.prototype.$cookie = cookie;
Vue.prototype.$Swal = Swal;

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
