import Vue from 'vue';
import Vuex from 'vuex';
import cookie from 'js-cookie';
import Swal from 'sweetalert2';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    sidebar: false,
  },
  mutations: {
    SET_LOGIN(state, payload) {
      state.token = payload;
    },
    SET_LOGOUT(state) {
      cookie.remove('token');
      state.token = null;
    },
    SET_SIDEBAR(state) {
      state.sidebar = !state.sidebar;
    }
  },
  actions: {
    async logout({ commit }) {
      const customBtn = Swal.mixin({
        customClass: {
          confirmButton: 'button is-danger mr-1',
          cancelButton: 'button is-light ml-1'
        },
        buttonsStyling: false
      });
      const result = await customBtn.fire({
        title: 'Apakah Anda yakin?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak'
      });

      if (result.isConfirmed) {
        Swal.fire({
          title: 'Berhasil!',
          text: 'Anda telah logout',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          commit('SET_LOGOUT');
          window.location.reload();
        });
      }
    },
    setSidebar({ commit }) {
      commit('SET_SIDEBAR');
    },
    setLogin({ commit }, payload) {
      commit('SET_LOGIN', payload);
    }
  }
});
