import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import NotFound from "@/pages/NotFoundPage.vue";

const Login = () => import(/* webpackChunkName: "common" */ "@/pages/login/Login.vue");
const Profile = () => import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
const Icons = () => import(/* webpackChunkName: "common" */ "@/pages/Icons.vue");
const TableList = () => import(/* webpackChunkName: "common" */ "@/pages/m-organization/list/ListOrg.vue");
const CreateOrg = () => import(/* webpackChunkName: "common" */ "@/pages/m-organization/create/CreateOrg.vue");
const EditOrg = () => import(/* webpackChunkName: "common" */ "@/pages/m-organization/existing/ExistingOrg.vue");
const ListOrgAsset = () => import(/* webpackChunkName: "common" */ "@/pages/m-organization-asset/list/ListOrgAsset.vue");
const CreateOrgAsset = () => import(/* webpackChunkName: "common" */ "@/pages/m-organization-asset/create/CreateOrgAsset.vue");
const EditOrgAsset = () => import(/* webpackChunkName: "common" */ "@/pages/m-organization-asset/existing/ExistingOrgAsset.vue");
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard.vue");

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      guest: true
    }
  },
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    children: [
      {
        path: 'profile',
        name: 'profile',
        component: Profile,
        meta: { requireAuth: true }
      },
      {
        path: 'icons',
        name: 'icons',
        component: Icons,
        meta: { requireAuth: true }
      },
      {
        path: 'organization',
        name: 'organization',
        component: TableList,
        meta: { requireAuth: true }
      },
      {
        path: 'create-organization',
        name: 'create_organization',
        component: CreateOrg,
        meta: { requireAuth: true }
      },
      {
        path: '/detail-organization/:id',
        name: 'edit_organization',
        component: EditOrg,
        meta:{
          requireAuth: true
        }
      },
      {
        path: '/organization-asset',
        name: 'organization_asset',
        component: ListOrgAsset,
        meta:{
          requireAuth: true
        }
      },
      {
        path: '/create-organization-asset',
        name: 'create_organization_asset',
        component: CreateOrgAsset,
        meta:{
          requireAuth: true
        }
      },
      {
        path: '/detail-organization-asset/:id',
        name: 'detail_organization_asset',
        component: EditOrgAsset,
        meta:{
          requireAuth: true
        }
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          requireAuth: true
        }
      },
    ],
  },
  { path: '*', component: NotFound },
];

export default routes;
