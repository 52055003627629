import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store'; 
import routes from './routes'; 

Vue.use(Router);

const router = new Router({
  mode: 'hash', 
  routes 
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)) {
    if (store.state.token == null) {
      next('/login');
    }
  } if (to.matched.some(record => record.meta.guest)) {
    if (store.state.token !== null) {
      next('/');
    }
  }
  next();
});

export default router;
