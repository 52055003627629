<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
      <tr>
        <slot name="columns">
          <th v-for="column in columns" :key="column">{{ column }}</th>
        </slot>
      </tr>
    </thead>
    <tbody :class="tbodyClasses">
      <tr v-for="(item, index) in data" :key="index">
        <slot :row="item">
          <template v-for="(column) in columns">
            <td>
                <template v-if="column === 'no'">
                  {{ index+1 }}
                </template>
                <template v-else-if="column === 'Nama'">
                  {{ item["name"] }}
                </template>
                <template v-else-if="column === 'Kontak'">
                  {{ item["contact"] }}
                </template>
                <template v-else-if="column === 'Alamat'">
                  {{ item["address"] }}
                </template>
                <template v-else-if="column === 'Gambar'">
                  <img :src="item['imageUrl']" alt="" width="50">
                </template>
                <template v-else-if="column === 'Aksi'">
                  <base-button type="info" class="mr-1" @click="editOrg(item['_id'])">
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                  <base-button type="danger" class="ml-1" fill @click="deleteOrg(item['_id'])">
                    <i class="tim-icons icon-trash-simple"></i>
                  </base-button>
                </template>
            </td>
          </template>
        </slot>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: "base-table",
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: "Table columns",
    },
    data: {
      type: Array,
      default: () => [],
      description: "Table data",
    },
    type: {
      type: String, // striped | hover
      default: "",
      description: "Whether table is striped or hover type",
    },
    theadClasses: {
      type: String,
      default: "",
      description: "<thead> css classes",
    },
    tbodyClasses: {
      type: String,
      default: "",
      description: "<tbody> css classes",
    },
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    async deleteOrg(id){
      const customBtn = this.$Swal.mixin({
          customClass: {
              confirmButton: "button is-danger mr-1",
              cancelButton: "button is-light ml-1"
          },
          buttonsStyling: false
      });
      const result = await customBtn.fire({
          title: "Apakah Anda yakin?",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak"
      });
      if(result.isConfirmed){
          try {
              await this.$axios
              .delete("organizations/" + id)
              .then(() => {
                  this.$Swal.fire({
                      title: "Berhasil!",
                      text: "Organisasi berhasil dihapus!",
                      icon: "success",
                      showConfirmButton: false,
                      timer: 1500
                  }).then(() => {
                      window.location.reload()
                  })
              });
          } catch (error) {
            console.log(error)
          }
      }
    },
    editOrg(id){
        this.$router.push('/detail-organization/' + id);
    },
  },
};
</script>
<style></style>
